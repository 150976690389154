body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --MainGreen: rgb(37, 166, 65);
  --LightGreen: rgb(178, 230, 173);
  --MainWhite: rgb(255, 255, 255);
  --MainDark: rgb(0, 0, 0);
  --MainYellow: rgb(255, 217, 0);
}

body {
  font-family: "Roboto", sans-serif !important;
  background-color: rgb(255, 255, 255) !important;
  background-color: var(--MainWhite) !important;
  color: rgb(0, 0, 0) !important;
  color: var(--MainDark) !important;
}

.text-title {
  font-family: Arial, cursive, sans-serif;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
}

.text-blue {
  color: var(--mainBlue);
}

.text-bright {
  color: var(--lightblue);
}
.btn-black {
  background: transparent;
  text-transform: capitalize;
  font-size: 0.8rem !important;
  color: rgb(0, 0, 0);
  color: var(--MainDark);
  border-radius: 0 !important;
  border: 0.1rem solid rgb(0, 0, 0) !important;
  border: 0.1rem solid var(--MainDark) !important;
}
.btn-black:hover {
  background-color: rgb(0, 0, 0) !important;
  background-color: var(--MainDark) !important;
  color: rgb(255, 255, 255) !important;
  color: var(--MainWhite) !important;
}
.cart-icon {
  cursor: pointer;
  font-size: 1.2rem;
  color: rgb(255, 217, 0);
  color: var(--MainYellow);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
.wrapper {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
}

